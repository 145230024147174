exports = module.exports = require("../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "* {\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n}\n\n:root {\n  font-family: 'Roboto', sans-serif;\n  --primaryBlue: rgb(29, 90, 185);\n  --primaryRed: #ef5350;\n  --primaryRedDark: #b53634;\n  --white: rgb(255, 255, 255);\n  --gray: rgb(241, 241, 241);\n  --darkBlue: rgb(58, 68, 84);\n  --black: rgb(23, 23, 23);\n  --charcoal: #3c4655;\n}\n\nhtml,\nbody,\nbody > div {\n  height: 100vh;\n}\n\ninput {\n  padding-bottom: 1px !important;\n}\n\nhr {\n  margin-top: 16px;\n  margin-bottom: 16px;\n  height: 1px;\n  outline: none;\n  border: none;\n  border-bottom: 1px solid #ddd;\n}\n\n/* NOTE: These styles will be unnecessary when g2-ops-components provides all the components needed. */\n\ninput,\ntextarea {\n  font-size: 16px !important;\n}\n\ntextarea {\n  padding: 6px 0 0 5px !important;\n  color: #000 !important;\n}\n\nbody {\n  margin: 0;\n  padding: 0;\n  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',\n    'Helvetica Neue', sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;\n}\n\n.styles_index--AppContainer > div:first-of-type {\n  flex-shrink: 0;\n}\n\n.styles_index--AppFrame {\n  background: rgb(255, 255, 255);\n  background: var(--white);\n}\n\n[data-core-component='AppBar'] {\n  flex-shrink: 0 !important;\n}\n\n/* TODO: Custom ops-app-reset.css */\n\nbutton {\n  cursor: pointer;\n}\n\n.__loader > div:first-of-type {\n  width: 75px;\n  height: 75px;\n}\n\n._index-OpsPortalCore {\n  width: 100%;\n  height: calc(100vh - 100px);\n  overflow-y: auto;\n  padding-top: 50px;\n}\n\n._index-center {\n  display: flex;\n  justify-content: center;\n}\n", ""]);

// exports
exports.locals = {
	"OpsPortalCore": "_index-OpsPortalCore",
	"center": "_index-center"
};
import React, { useEffect, useState } from 'react'
import { Crumb } from '@copart/ops-tool-kit'
import { Loader } from '@copart/core-components'
import { accessHelpers } from '@copart/ops-tool-kit'
import {
  getAccessToken,
  getCountryCode2Digit,
  getCountryCode3Digit,
  getYardNumber,
  getSelectedRole,
  getLanguage,
  getStandardPrinter,
} from '../../utilities/storageUtils'
import './EmbeddedView.css'

const setPageTitle = (title) => {
  document.title = title
  const titleElement = document.getElementsByClassName('utilities_ops-app-frame_AppBarComponent_AppBar--appTitle')[0]
  if (titleElement) titleElement.innerHTML = title
}

const setupMessageHandlers = (props) => {
  window.addEventListener('message', (ev) => messageHandler(ev, props), false)
}

const copartDomains = [".copart.com", ".copart.co.uk", ".copart.ca", ".copartmea.com", ".copart.ie", ".copart.de", ".copart.fi", ".copart.es"]
const messageHandler = (event, props) => {
  const { origin, data } = event
  const isOriginCopartDomain = copartDomains.some(domain => origin.endsWith(domain))
  if (!isOriginCopartDomain) return
  if (data && data.eventType) {
    const { uri } = data.eventProps || {}
    const newUri = 'embedded' + props.route + (uri || '')
    switch (data.eventType) {
      case 'UPDATE_URI':
        window.history.pushState({}, '', newUri)
        //window.location.replace(`//${window.location.hostname}/${window.location.pathname}${uri}`)
        return
      case 'SESSION_EXPIRED':
        window.location.assign(`//${window.location.hostname}/${newUri}`)
        return
      default:
        return
    }
  }
}

const IframeComponent = (props) => {
  const [showLoading, setLoading] = useState(true)

  useEffect(() => {
    // setup event listener for iframe message events
    setupMessageHandlers(props)
    setPageTitle(props.title)
    const appIframe = document.getElementsByTagName('iframe')[0]
    if (appIframe) {
      appIframe.addEventListener('load', function (ele, ev) {
        setLoading(false)
      })
    } else {
      setLoading(false)
    }
    const wrapperLocationRef = window.location
    if (props.redirectToHomeUrls) {
      if (appIframe) {
        appIframe.addEventListener('load', function (ele, ev) {
          try {
            if (this.contentWindow.location) {
              const shouldRedirectToHomeScreen = props.redirectToHomeUrls.some(url => this.contentWindow.location.pathname.includes(url))
              if (shouldRedirectToHomeScreen) {
                wrapperLocationRef.reload()
              }
            }
          } catch (e) {
            console.log('Current iframe error: ', e)
          }
        })
      }
    }
    if (props.passHashToApp) {
      if (appIframe) {
        appIframe.addEventListener('load', function (ele, ev) {
          try {
            if (this.contentWindow) {
              const message = {
                eventType: "LOAD_URI",
                eventProps: {
                    uri: wrapperLocationRef.hash
                }
              }
              this.contentWindow.postMessage(message)
            }
          } catch (e) {
            console.log('Current iframe error: ', e)
          }
        })
      }
    }
    if (props.uriChangeEvents) {
      if (appIframe) {
        appIframe.addEventListener('load', function (ele, ev) {
          try {
            if (this.contentWindow) {
              const uri = wrapperLocationRef.pathname.split(props.route)[1] 
              const message = {
                eventType: "LOAD_URI",
                eventProps: {
                    uri
                }
              }
              this.contentWindow.postMessage(message, '*')
            }
          } catch (e) {
            console.log('Current iframe error: ', e)
          }
        })
        window.addEventListener('popstate', function(ev){
          console.log("location: " + document.location + ", state: " + JSON.stringify(ev.state))
        });
      }
    }
    // Clear Mihub session storage item on navigating away
    if (props.route.includes('mihub')) {
      window.addEventListener('beforeunload', function (ele, ev) {
        try {
          console.log('in unload')
          sessionStorage.removeItem('globalNavResponseObj')
        } catch (e) {
          console.log('Error: ', e)
        }
      })
    }
  }, [])

  const getQueryValue = (field) => {
    switch (field) {
      case 'accessToken':
        return getAccessToken()
      case 'countryCode2Digit':
        return getCountryCode2Digit()
      case 'countryCode3Digit':
        return getCountryCode3Digit()
      case 'yardNumber':
        return getYardNumber()
      case 'currentSelectedRole':
        return getSelectedRole()
      case 'language':
        return getLanguage()
      case 'standardPrinter':
        return getStandardPrinter()
      default:
        return ''
    }
  }

  const getIframeQuery = (params) => {
    let query = '?'
    Object.keys(params).forEach(field => {
      if (field === 'customParams' && params[field]) {
        query += `${params[field]}&`
      } else if (params[field]) {
        const queryValue = getQueryValue(field)
        query += `${params[field]}=${queryValue}&`
      }
    })
    return query.slice(0, -1)
  }

  const renderIframeApp = (paramsForUrl) => {
    const { title, url, ...params } = paramsForUrl
    const iframeSrc = url + getIframeQuery(params)
    const iframeRoute = props.route.slice(1, props.route.length)
    return (
      <div>
        <Crumb as={() => go()} to={props.route}>
          {title}
        </Crumb>
        <iframe
          title={title}
          styleName={`embeddedApp ${iframeRoute}`}
          src={iframeSrc}
          frameBorder="0"
        />
      </div>
    )
  }

  const paramsForUrl = {
    ...props.urlProps,
    url: props.url,
    title: props.title,
    customParams: props.customParams
  }

  const tileConfigAccess = accessHelpers.userHasAccess(props.tileConfig)
  return (
    <div>
      { showLoading && <Loader size='sm' />}
      {
        tileConfigAccess ?
          renderIframeApp(paramsForUrl) :
          (
            <div style={{ textAlign: 'center' }}>Could not verify user access for the tile.</div>
          )
      }
    </div>
  )
}

export default IframeComponent
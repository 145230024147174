import { hot } from 'react-hot-loader'
import * as React from 'react'
import { reduce, compose, propOr, isEmpty } from 'ramda'
import { storage, fetcher } from '@copart/ops-tool-kit'
import { Route, withRouter, Redirect, Switch } from 'react-router-dom'
import { Loader } from '@copart/core-components'

import { getPrintersForYard } from '#utilities/printers/getPrinters'
import { EMPTY_PRINTERS } from '#consts/printers'
import findHomeYard from '#utilities/findHomeYard'

import { HomeView, SettingsView, LoginView, OktaView, LogoutView, EmbeddedView } from './views'
import './index.css'

// TODO: Talk to services about persisting user settings in DB.

const OKTA_CONFIG_ERROR = 'There is an error when getting Okta Config'

const CenteredSpinner = () => (
  <div styleName="center">
    <Loader />
  </div>
)

const makeKeyValue = (final, target) => {
  const defaultPrinter = target[1].find((t) => t.isDefault)
  final[target[0]] = defaultPrinter?.printerName || ''
  return final
}

const formatActivePrinters = (activePrinters) => {
  return reduce(makeKeyValue, {}, Object.entries(activePrinters))
}

const RootRedirect = () => <Redirect to="/" />

@withRouter
class AppRouter extends React.PureComponent {
  state = {
    gotConfig: false,
    isOktaEnable: false,
  }

  componentDidMount() {
    if (storage.isAuthenticated) {
      this.setupStorage()
    } else {
      this.fetchOktaConfig()
    }
  }

  setupStorage = async () => {
    const login = storage.getLocalItem('login') || {}
    const lastSelectedYard = storage.getLocalItem('lastSelectedYard')
    if (global.STACK !== 'g') {
      const homeYard = findHomeYard(login.yardList) || login.yardList[0]

      if (!storage.getSessionItem('dashboard', 'selectedYard')) {
        storage.setSessionItem('dashboard', {
          ...storage.getSessionItem('dashboard'),
          selectedCurrency: lastSelectedYard.yard_currency,
          selectedCountry: lastSelectedYard.yard_country_code.slice(0, 2),
          selectedCountryA3code: lastSelectedYard.yard_country_code,
          selectedYard: lastSelectedYard.yard_number,
          language: 'en',
          selectedTimeZone: lastSelectedYard.time_zone,
          yard: lastSelectedYard,
          homeYard: homeYard.number,
        })
      }
      const getLastSelectedYard = (yardList = []) =>
        yardList.find((yard) => yard.number === Number(lastSelectedYard.yard_number))

      const pickUserSecurityLevel = (obj) => +propOr('0', 'userSecurityLevel', obj)

      const securityLevel = compose(
        pickUserSecurityLevel,
        getLastSelectedYard,
      )(login?.yardList)

      storage.setSessionItem('securityLevel', securityLevel)

      const selectedYardNumber = storage.activeYardNumber
      const printersData = storage.getSessionItem('dashboard', 'printers') || {}

      // Printer settings
      if (process.env.STACK === 'c' && isEmpty(printersData)) {
        const localStoragePrinters = storage.getLocalItem('dashboard', 'printers') || {}
        const data = await getPrintersForYard(selectedYardNumber)

        storage.setSessionItem(
          'dashboard',
          {
            ...formatActivePrinters(data),
            ...printersData,
            ...localStoragePrinters,
          },
          'printers',
        )
      }
    } else {
      storage.setSessionItem('dashboard', storage.getLocalItem('dashboard'))
    }
    sessionStorage.setItem('storageUpdated', true)

    this.setState({
      gotConfig: true,
    })
  }

  fetchOktaConfig = async () => {
    try {
      const config = await fetcher.getOktaConfig()
      if (config.error) {
        console.error(OKTA_CONFIG_ERROR, config.error)
        this.setState({
          gotConfig: true,
          isOktaEnable: false,
          OKTA_LOGIN_URI: '',
          SSO_LOGOUT_URI: '',
        })
        return
      }

      this.setState({
        gotConfig: true,
        isOktaEnable: config.OKTA_ENABLE,
        OKTA_LOGIN_URI: config.OKTA_LOGIN_URI,
        SSO_LOGOUT_URI: config.SSO_LOGOUT_URI,
      })
    } catch (error) {
      console.error(OKTA_CONFIG_ERROR, error)
      this.setState({
        gotConfig: true,
        isOktaEnable: false,
      })
    }
  }

  render() {
    const showOktaView =
      this.state.isOktaEnable && !window.location.origin.includes('localhost')
    return (
      <div styleName="OpsPortalCore">
        <Switch>
          <Choose>
            <When condition={!this.state.gotConfig}>
              <Route exact path="/" component={CenteredSpinner} />
            </When>
            <When condition={storage.isAuthenticated}>
              <Route exact path="/" component={HomeView} />
              <Route exact path="/settings" component={SettingsView} />
              <Route path="/embedded" component={EmbeddedView} />
              <Route path="*" component={RootRedirect} />
            </When>
            <Otherwise>
              <Route
                exact
                path="/logout"
                component={() => (
                  <LogoutView
                    OKTA_LOGIN_URI={this.state.OKTA_LOGIN_URI}
                    SSO_LOGOUT_URI={this.state.SSO_LOGOUT_URI}
                  />
                )}
              />
              <Route
                path="/login"
                component={
                  showOktaView
                    ? () => <OktaView OKTA_LOGIN_URI={this.state.OKTA_LOGIN_URI} />
                    : LoginView
                }
              />
              <Redirect push to="/login" />
            </Otherwise>
          </Choose>
        </Switch>
      </div>
    )
  }
}

export default hot(module)(AppRouter)
